import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../../../sections/Navbar/Navbar'
import Footer from '../../../sections/Footer'
import { PrismicRichText } from "@prismicio/react";
import "../../../styles/AdminPage.scss";

const CoachelloToColleagues = ({ data }: any) => {
  const info = data.prismicHowToMakeCoachelloAvailableToMyColleagues.dataRaw
  const imgInfo = data.prismicHowToMakeCoachelloAvailableToMyColleagues.data
  console.log(data)
  return (
    <div>
      <Navbar />
      <div className='AdminPage'>
        <div className='AdminPage-title'>
          <PrismicRichText field={info.title} />
        </div>
        <div className='AdminPage-second-title'>
          <PrismicRichText field={info.updated} />
        </div>
        <div>
          <span className='AdminPage-title'>
            <PrismicRichText field={info.title1} />
          </span>
        </div>
        <div className='AdminPage-content'>
          <PrismicRichText field={info.text} />
          <img className="AdminPage-image" src={imgInfo.image_1.url} alt={imgInfo.image_1.alt} />
          <PrismicRichText field={info.text1} />
          <img className="AdminPage-image" src={imgInfo.image_3.url} alt={imgInfo.image_3.alt} />
          <img className="AdminPage-image" src={imgInfo.image_4.url} alt={imgInfo.image_4.alt} />
          <PrismicRichText field={info.text2} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CoachelloToColleagues

export const query = graphql`
query CoachelloToColleagues {
    prismicHowToMakeCoachelloAvailableToMyColleagues(lang: {eq: "fr-fr"}) {
    dataRaw
    data {
      image_1 {
        url
        alt
      }
      image_3 {
        alt
        url
      }
      image_4 {
        alt
        url
      }
      logo {
        alt
        url
      }
    }
  }
}
`;
